import { commonHelpers } from "@/utils/helpers";
import { storeCommonSelectors } from "@/store";

import { Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import AppLink from "@/components/AppLink";
import AppImage from "@/components/AppImage";
import AppSvgIcon from "@/components/AppSvgIcon";
import AppIconButton from "@/components/AppIconButton";
import AppContainer from "@/components/AppContainer";
import AppTypography from "@/components/AppTypography";

import WhatsappIcon from "@@/public/images/icons/whatsapp.svg";
import FacebookIcon from "@@/public/images/icons/facebook.svg";
import InstagramIcon from "@@/public/images/icons/instagram.svg";
import YoutubeIcon from "@@/public/images/icons/youtube.svg";
import EmailIcon from "@@/public/images/icons/email.svg";
import PhoneIcon from "@@/public/images/icons/phone.svg";

import { useTranslation } from "next-i18next";
import { useAppSelector } from "@/hooks";

import useStyles from "./MainFooter.styles";
import { commonConfig } from "@/utils/config";

const NavigationList = () => {
  const { t } = useTranslation();

  const $s_services = useAppSelector((state) => state.service.services);
  const $s_servicesLoading = useAppSelector(
    (state) => state.service.servicesLoading
  );
  const $s_servicesError = useAppSelector(
    (state) => state.service.servicesError
  );

  const firstService = $s_services[0];

  const serviceLoading = !!$s_servicesLoading || !!$s_servicesError;

  const navigationItems = [
    {
      title: t("home"),
      href: "/",
    },
    {
      title: t("services"),
      href: !!firstService
        ? `/services/${commonHelpers.generateSlug(
            firstService?.ServiceLanguage?.name,
            firstService?.id
          )}`
        : "",
      disabled: serviceLoading,
    },
    {
      title: t("studyingInJapan"),
      href: "/studying-in-japan/school-categories",
    },
    {
      title: t("accommodation"),
      href: "/accommodation/accommodation-in-japan",
    },
    {
      title: t("tips"),
      href: "/tips",
    },
  ];

  return navigationItems.map((navigationItem, navigationItemIndex) => (
    <div key={`index-${navigationItemIndex}`}>
      <AppLink
        variant="titleMed20"
        {...(!!navigationItem.disabled
          ? {
              color: "text.secondary",
              disabledNextLink: true,
              hoverColor: "none",
              underline: "none",
              href: "",
              sx: {
                pointerEvents: "none",
              },
            }
          : {
              href: navigationItem.href,
            })}
      >
        {navigationItem.title}
      </AppLink>
    </div>
  ));
};

const SocialContact = () => {
  const { classes } = useStyles();

  const $s_socialContactItem = useAppSelector(
    storeCommonSelectors.selectSocialContactItem
  );
  const $s_socialContactLoading = useAppSelector(
    (state) => state.service.servicesLoading
  );
  const $s_socialContactError = useAppSelector(
    (state) => state.service.servicesError
  );

  const socialContactLoading =
    $s_socialContactLoading || !!$s_socialContactError;

  return (
    <div className={classes.contact}>
      <div className={classes.contactInfo}>
        <div className={classes.contactInfoItem}>
          <AppSvgIcon component={PhoneIcon} />
          <AppLink
            disabledNextLink
            underline="none"
            variant="titleMed20"
            lineHeight="24px"
            {...(!socialContactLoading
              ? {
                  href: `tel:${$s_socialContactItem.phone?.data}`,
                }
              : {
                  href: "",
                  sx: {
                    pointerEvents: "none",
                  },
                })}
          >
            {socialContactLoading ? (
              <Skeleton variant="text" sx={{ width: 120, maxWidth: "100%" }} />
            ) : (
              $s_socialContactItem.phone?.data
            )}
          </AppLink>
        </div>
        <div className={classes.contactInfoItem}>
          <AppSvgIcon component={EmailIcon} />
          <AppLink
            disabledNextLink
            href={`mailto:${$s_socialContactItem.email?.data}`}
            variant="titleMed20"
            lineHeight="24px"
          >
            {socialContactLoading ? (
              <Skeleton variant="text" sx={{ width: 120, maxWidth: "100%" }} />
            ) : (
              $s_socialContactItem.email?.data
            )}
          </AppLink>
        </div>
      </div>
      <div className={classes.contactSocialMedia}>
        <AppIconButton
          variant="contained"
          borderRadius="circular"
          color="socialMedia.whatsapp"
          component={AppLink}
          disabledNextLink
          href={commonHelpers.generateWhatsAppLink(
            $s_socialContactItem.whatsapp?.data! ?? ""
          )}
          target="_blank"
          hoverColor="none"
          underline="none"
          disabled={socialContactLoading}
        >
          <AppSvgIcon component={WhatsappIcon} fontSize="inherit" />
        </AppIconButton>
        <AppIconButton
          variant="contained"
          borderRadius="circular"
          color="socialMedia.facebook"
          component={AppLink}
          disabledNextLink
          href={$s_socialContactItem.facebook?.data ?? ""}
          target="_blank"
          hoverColor="none"
          underline="none"
          disabled={socialContactLoading}
        >
          <AppSvgIcon component={FacebookIcon} fontSize="inherit" />
        </AppIconButton>
        <AppIconButton
          variant="contained"
          borderRadius="circular"
          color="text.primary"
          className={classes.instagramButton}
          classes={{
            disabled: classes.disabled,
          }}
          component={AppLink}
          disabledNextLink
          href={$s_socialContactItem.instagram?.data ?? ""}
          target="_blank"
          hoverColor="none"
          underline="none"
          disabled={socialContactLoading}
        >
          <AppSvgIcon component={InstagramIcon} fontSize="inherit" />
        </AppIconButton>
        <AppIconButton
          variant="contained"
          borderRadius="circular"
          color="socialMedia.youtube"
          component={AppLink}
          disabledNextLink
          href={$s_socialContactItem.youtube?.data ?? ""}
          target="_blank"
          hoverColor="none"
          underline="none"
          disabled={socialContactLoading}
        >
          <AppSvgIcon component={YoutubeIcon} fontSize="inherit" />
        </AppIconButton>
      </div>
    </div>
  );
};

const MainFooter = () => {
  const { classes } = useStyles();

  const { t } = useTranslation();

  const informationItems = [
    {
      title: t("termsAndConditions"),
      href: "/terms-and-conditions",
    },
    {
      title: t("privacyPolicy"),
      href: "/privacy-policy",
    },
    {
      title: t("cookiePolicy"),
      href: "/cookie-policy",
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <AppContainer>
          <Grid container justifyContent="space-between" spacing={3.75}>
            <Grid size={{ xs: 12, md: "auto" }} order={1}>
              <AppLink
                href="/"
                underline="none"
                hoverColor="none"
                display="flex"
              >
                <AppImage
                  className={classes.logoImg}
                  src={"/images/logo-text.svg"}
                  defaultPlaceholderVariant="none"
                  width={500}
                  height={500}
                />
              </AppLink>
            </Grid>
            <Grid size={{ xs: 6, md: "auto" }} order={2}>
              <AppTypography
                className={classes.navItemTitle}
                variant="titleSemi24"
              >
                {t("navigation")}
              </AppTypography>
              <div className={classes.navItemContent}>
                <NavigationList />
              </div>
            </Grid>
            <Grid size={{ xs: 6, md: "auto" }} order={3}>
              <AppTypography
                className={classes.navItemTitle}
                variant="titleSemi24"
              >
                {t("information")}
              </AppTypography>
              <div className={classes.navItemContent}>
                {informationItems.map(
                  (informationItem, informationItemIndex) => (
                    <div key={`index-${informationItemIndex}`}>
                      <AppLink variant="titleMed20" href={informationItem.href}>
                        {informationItem.title}
                      </AppLink>
                    </div>
                  )
                )}
              </div>
            </Grid>
            <Grid size={{ xs: 12, md: "auto" }} order={{ xs: 1, md: 4 }}>
              <AppTypography
                className={classes.navItemTitle}
                variant="titleSemi24"
                display={{
                  xs: "none",
                  md: "block",
                }}
              >
                {t("contact")}
              </AppTypography>

              <SocialContact />
            </Grid>
          </Grid>
        </AppContainer>
      </div>
      <div className={classes.allRightsReserved}>
        <AppContainer>
          <AppTypography align="center">
            {t("allRightsReservedContent_text")} | v.{commonConfig.APP_VERSION}
          </AppTypography>
        </AppContainer>
      </div>
    </div>
  );
};

export default MainFooter;
