import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "AppListItem",
  uniqId: "qWpSuI",
})((theme) => {
  return {
    root: {
      minHeight: 44,
    },
    padding: {
      padding: theme.spacing(1.25),
    },
  };
});

export default useStyles;
